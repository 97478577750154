<template>
  <el-row>
    <el-col :span="24">
      <router-link style="float: left" to="/">
        <el-button style="padding-top: 0px" type="text">
          <el-image
            :src="logoImage"
            fit="contain"
            style="height: 90px; margin-top: -15px"
          ></el-image>
        </el-button>
      </router-link>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      logoImage: require("@/assets/imgs/blockstory_neg.png"),
    };
  },
  components: {},
  computed: {},
};
</script>

<style lang="scss"></style>
