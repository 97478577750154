<template>
  <el-row class="home-banner regular-text">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <el-row>
        <el-col :span="1" :xs="0"></el-col>

        <el-col :span="11" :xs="24" style="text-align: left">
          <p class="banner-title color-blue">
            <span class="color-green">mint</span> or reserve
          </p>
          <p class="banner-title no-top-margin color-blue">your blockstory</p>
          <p>
            To be the first in your blockstory you can
            <el-tooltip
              content="Produce a new story-genesis."
              effect="dark"
              placement="top-start"
            >
              <el-button class="tooltip-text regular-text" type="text"
                >Mint
              </el-button>
            </el-tooltip>
            or
            <el-tooltip
              content="Book an empty story-genesis without finalizing it."
              effect="dark"
              placement="top-start"
            >
              <el-button class="tooltip-text regular-text" type="text"
                >Reserve
              </el-button>
            </el-tooltip>
            your Story-Node.
          </p>

          <p>
            Minting generates ownable NFT Genesis-Node (ie. a new story) with
            random
            <el-tooltip
              content="Each blockstory has max depth and max number of choices determined by rarity"
              effect="dark"
              placement="top-start"
            >
              <el-button class="tooltip-text regular-text" type="text"
                >Rarity
              </el-button>
            </el-tooltip>
            .
          </p>
          <p>
            If you're not feeling inspired, it's possible to reserve the
            Genesis-Node and finalize it later!
          </p>
        </el-col>
        <el-col :span="1" :xs="0"></el-col>
        <el-col :span="11" :xs="24">
          <el-image
            :src="mintReserveImage"
            fit="contain"
            style="height: 60vh; margin-bottom: -1vh"
          ></el-image>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="2"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "MintOrReserveBanner",
  data() {
    return {
      mintReserveImage: require("@/assets/imgs/elements/lightbulb.png"),
    };
  },
};
</script>

<style scoped></style>
