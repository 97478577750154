<template>
  <el-header class="bg-gradient">
    <nav-bar></nav-bar>
  </el-header>
  <el-divider class="thin-divider"></el-divider>

  <router-view style="min-height: 100vh" />

  <el-footer>
    <bot-bar></bot-bar>
  </el-footer>
</template>

<script>
import NavBar from "@/views/NavBar";
import BotBar from "@/views/BotBar";

export default {
  components: { BotBar, NavBar },
};
</script>

<style>
#app {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5c6977;
}

body {
  margin: 0px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.thin-divider {
  margin: 0 !important;
  height: 0px !important;
}

.el-popper {
  font-size: 0.9rem !important;
  background-color: #005b9e !important;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.el-popper__arrow::before {
  background: #005b9e !important;
}
</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Roboto");
</style>
