import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueGtag from "vue-gtag";

createApp(App)
  .use(ElementPlus)
  .use(store)
  .use(router)
  // todo Add this
  .use(VueGtag, {
    config: { id: "G-5502SFHVD5" },
  })
  .mount("#app");
