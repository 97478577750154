<template>
  <el-row>
    <el-col :span="4" />
    <el-col :span="16">
      <el-image
        :src="notFoundImage"
        fit="contain"
        style="height: 95vh; margin-bottom: -1vh"
      ></el-image>
    </el-col>
    <el-col :span="4" />
  </el-row>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      notFoundImage: require("@/assets/imgs/404/error404.png"),
    };
  },
};
</script>

<style scoped></style>
